<template>

<v-container>

  <v-row>
    <v-col cols="11">
      <v-card>
        <v-card-title>
                  <v-icon dark color="indigo" x-large>local_atm</v-icon>
         <span class="px-1"> Graficos de costos </span>

         <v-divider vertical class="mx-4"></v-divider>
          <v-spacer></v-spacer>

           <v-flex xs4 md3 xl2 sm2 lg2 mx-4>
      
                    <v-menu
                      ref="menuFecha1"
                      v-model="menuFecha1"
                      :close-on-content-click="false"
                      :return-value.sync="date"
                      transition="scale-transition"
                  
                      offset-y
                      min-width="290px"
                    >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="fecha_inicio"
                              label="Fecha inicio"
                              prepend-icon="event"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker v-model="fecha_inicio" locale="es"
                          no-title  @input="menuFecha1 = false" scrollable>
                          </v-date-picker>
                    </v-menu>
              </v-flex>

          <v-flex xs4 md2 xl2 sm2 lg2 mx-4>
                 <v-menu
                  ref="menuFecha2"
                  v-model="menuFecha2"
                  :close-on-content-click="false"
                  :return-value.sync="date"
                  transition="scale-transition"
              
                  offset-y
                  min-width="290px"
                >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="fecha_fin"
                          label="Fecha fin"
                          prepend-icon="event"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker v-model="fecha_fin" locale="es"
                       no-title  @input="menuFecha2 = false" scrollable>
                      </v-date-picker>
                </v-menu>

              </v-flex>


                  <v-flex justify-center d-flex>
                      <v-btn color="success" dark @click="GenerarReporteFechas()" style="margin-right:10px;">Generar</v-btn>
                  </v-flex>

          </v-card-title>
      </v-card>
    </v-col>
  </v-row>

    <v-row>


        <v-col cols="6" sm="12" md="6" lg="6" xl="6">
        <v-card>

            <v-card-title>
              
               <v-icon color="indigo" class="mx-1">payments</v-icon> 
              <span class="mx-1">Costo unidad</span>
              
                <v-row>
                <v-col  cols="11" xs="11" sm="11" md="11" lg="11" xl="11">
                <v-select 
                            v-model="flota_select"
                            :items="FlotaArrayItems"
                            label="Flota"
                            @change="ActualizarGraficasVeh()"
                  ></v-select>
                </v-col>
                </v-row>


            </v-card-title>
            <v-card-text>
                    <apexchart width="500" type="bar" :options="optionsVehiculos" :series="seriesVehiculos"></apexchart>  
            </v-card-text>
        </v-card>
        </v-col>

      <v-col cols="6" sm="12" md="6" lg="6" xl="6">
        <v-card>

            <v-card-title>
              <v-icon color="indigo" class="mx-1">commute</v-icon>
              <span class="mx-1">Costos mantenimiento por flota</span>
              </v-card-title>
            <v-card-text>
                    <apexchart width="500" height="400" type="bar" :options="optionsFlota" :series="seriesFlota"></apexchart>


                    <v-divider class="mt-2"></v-divider>
                    <v-icon  class="mr-2" small>paid</v-icon>
                    <span >Costo total en rango fechas:
                      <v-chip class="ma-2"  dark color="red"> $ {{ costo_total_mantenimientos }}</v-chip>
                    </span>


            </v-card-text>
        </v-card>
      </v-col>


        <v-col cols="6" sm="12" md="6" lg="6" xl="6">
        <v-card>

            <v-card-title>
              
              <v-icon color="indigo" class="mx-1">battery_charging_full</v-icon> 
              <span class="mx-1">Costos Baterias</span>
              </v-card-title>
            <v-card-text>
                    <apexchart width="500" type="bar" :options="optionsBateria" :series="seriesBateria"></apexchart>  


                      <v-divider class="mt-4"></v-divider>
                    <v-icon  class="mr-2" small>paid</v-icon>
                    <span>Costo total- Baterias en rango fechas:
                       <v-chip class="ma-2"  dark color="purple">$ {{ costo_total_baterias }}</v-chip>
                    </span>

            </v-card-text>
        </v-card>
      </v-col>



        <v-col cols="6" sm="12" md="6" lg="6" xl="6">
          <v-card>

            <v-card-title>
              <v-icon color="indigo" class="mx-1">data_saver_on</v-icon> 
              <span class="mx-1">Costo llantas</span>
              </v-card-title>
            <v-card-text>
                    <apexchart width="500" type="bar" :options="optionsLlanta" :series="seriesLlanta"></apexchart>  

                     <v-divider class="mt-4"></v-divider>
                    <v-icon  class="mr-2" small>paid</v-icon>
                    <span>Costo total- Llantas en rango fechas:
                      
                       <v-chip class="ma-2"  dark color="blue">${{ costo_total_llantas }}</v-chip></span>

            </v-card-text>
        </v-card>
      </v-col>

       
      
    </v-row>


    

</v-container>
    
</template>




<script>

import axios from 'axios';
import { mapMutations,mapState } from "vuex";
import ApexCharts from "apexcharts";



export default {
  data(){
         return{

             arrayitemFlota:[],
             ArrayLlantas:[],
             ArrayBaterias:[],
             ArrayVehiculos:[],

             menuFecha1:false,
             fecha_inicio:new Date().toISOString().substr(0, 10),
             menuFecha2:false,
             fecha_fin:new Date().toISOString().substr(0, 10),
             date: new Date().toISOString().substr(0, 10),
            

             options: {
                        chart: {
                        id: 'vuechart-example1'
                        },
                        xaxis: {
                        categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998]
                        }
                    },
            series: [{
                name: 'series-1',
                data: [30, 40, 45, 50, 49, 60, 70, 91]
            }],




            optionsFlota:{},
            seriesFlota:[],
            data_local_flotas:[],
            label_local_flotas:[],


             optionsBateria:{},
             seriesBateria:[],
             data_local_Bateria:[],
             label_local_Bateria:[],

             optionsLlanta:{},
             seriesLlanta:[],
             data_local_Llanta:[],
             label_local_Llanta:[],



             optionsVehiculos:{},
             seriesVehiculos:[],
             data_local_Vehiculos:[],
             label_local_Vehiculos:[],

             flota_select:'',
             FlotaArrayItems:[],

             arreglo_local_veh:[],



            data_local_Vehiculos_bateria:[],
            data_local_Vehiculos_llanta:[],
            data_local_Vehiculos_mantenimiento:[],

            respuestas_cantidad:0,


              //totales bajo graficas
            costo_total_mantenimientos:0,
            costo_total_baterias:0,
            costo_total_llantas:0,





           

         }
 }, 

 created () {

   let fecha_actual=new Date();
       fecha_actual.setMonth(0);
       fecha_actual.setDate(1);

   this.fecha_inicio=new Date(fecha_actual).toISOString().substr(0, 10);


   this.listarVehiculos();
  
 },

 methods:{

      ...mapMutations(['mostrarLoading','ocultarLoading']),

       listarVehiculos(){

                 this.ArrayVehiculos=[];

                 this.mostrarLoading({titulo:'Accediendo a datos'});
                 let config={headers:{token:this.token}};
                let me=this;
                axios.get(`vehiculos/${this.usuario.id_usr_empresa._id}`,
                    config
                    ).then(function(response){
                        console.log(response);
                        me.ArrayVehiculos=response.data;
                        me.ocultarLoading();

                        me.listar();
                    }).catch(function(error){ console.log(error); });

          },



          listar(){

               this.arrayitemFlota=[];

                 this.mostrarLoading({titulo:'Accediendo a datos'});
                 let config={headers:{token:this.token}};
                let me=this;
                axios.get(`flotas/${this.usuario.id_usr_empresa._id}`,
                    config
                    ).then(function(response){
                      
                        me.arrayitemFlota=response.data;

                      me.arrayitemFlota.map(function(x){
                          me.FlotaArrayItems.push({text:x.nombre_flota, value:x.nombre_flota});
                      });

                      if(me.arrayitemFlota.length >0){
                           me.flota_select=me.FlotaArrayItems[0].value;
                      }
                      



                        me.ocultarLoading();
                        me.GenerarReporteFechas();

                          console.log(me.arrayitemFlota);
                    }).catch(function(error){ console.log(error); });

          },




          GenerarReporteFechas(){

               this.ArrayLlantas=[];
               
                  this.costo_total_llantas=0;



                 this.mostrarLoading({titulo:'Accediendo a datos'});
                 let config={headers:{token:this.token}};
                 let me=this;
                 axios.post('llantas-historico',
                        {
                        'desde':this.fecha_inicio,
                        'hasta':this.fecha_fin,
                        'id_empresa':this.usuario.id_usr_empresa._id
                        },
                    config
                    ).then(function(response){
                        console.log(response);
                        me.ArrayLlantas=response.data;
                        me.ocultarLoading();

                    
                        //recorremos las flotas

                          me.arrayitemFlota.map(function(y){
                          me.ArrayLlantas.map(function(x){
                            if(x.id_vehiculo_llanta.flota==y.nombre_flota){
                                if(y.costo_llantas){
                                    y.costo_llantas=parseFloat(x.costo)+parseFloat(y.costo_llantas);
                                }else{
                                    y.costo_llantas=parseFloat(x.costo)
                                }
                            }
                          });//recorrmos las baterias 
                      });



                        me.data_local_Llanta=[];
                        me.label_local_Llanta=[];
                       me.arrayitemFlota.map(function(y){
                          if((y.costo_llantas )&& (parseFloat(y.costo_llantas) >0)){
                            me.data_local_Llanta.push(y.costo_llantas);
                            me.label_local_Llanta.push(y.nombre_flota);

                            me.costo_total_llantas=parseFloat(y.costo_llantas)+parseFloat(me.costo_total_llantas);
                            }
                       });




                        me.optionsLlanta={
                                     chart:{
                                        height: 350,
                                        type: 'bar',
                                      },
                                      plotOptions: {
                                        bar: {
                                          horizontal: false,
                                          columnWidth: '55%'
                                        },
                                      },
                                      
                                       title: {
                                        text: ''
                                      },
                                        subtitle: {
                                        text: '',
                                        align: 'center',
                                    },

                                      dataLabels: {
                                        enabled: false,
                                         enabledOnSeries: [1]
                                      },

                                      stroke: {
                                        show: true,
                                        width: 2,
                                        colors: ['transparent']
                                      },
                                      xaxis: {
                                        categories:  me.label_local_Llanta,
                                      },

                                      
                                     yaxis:[],
                                      fill: {
                                            opacity: 1
                                          }    


                                   };


                      me.seriesLlanta=[{
                            name: 'costos por flota',
                            data: me.data_local_Llanta
                        }];

          
                      me.GenerarDatosBaterias();

                    }).catch(function(error){ console.log(error); });


      },


 GenerarDatosBaterias(){ 
            
                 this.ArrayBaterias=[];
                 this.mostrarLoading({titulo:'Accediendo a datos'});
                  let config={headers:{token:this.token}};
                 let me=this;
                  this.costo_total_baterias=0;
              
               
                 axios.post('baterias-historico',
                        {
                        'desde':this.fecha_inicio,
                        'hasta':this.fecha_fin,
                        'id_empresa':this.usuario.id_usr_empresa._id
                        },
                    config
                    ).then(function(response){
                        console.log(response);
                        me.ArrayBaterias=response.data;
                        me.ocultarLoading();


                       me.arrayitemFlota.map(function(y){

                          me.ArrayBaterias.map(function(x){
                            if(x.id_vehiculo_bat.flota==y.nombre_flota){

                                if(y.costo_baterias){
                                    y.costo_baterias=parseFloat(x.costo)+parseFloat(y.costo_baterias);
                                }else{
                                    y.costo_baterias=parseFloat(x.costo)
                                }

                            }

                          });//recorrmos las baterias 

                      });//recorremos las flotas


                       me.data_local_Bateria=[];
                        me.label_local_Bateria=[];

                       me.arrayitemFlota.map(function(y){

                            if((y.costo_baterias )&& (parseFloat(y.costo_baterias) >0)){

                            
                            me.data_local_Bateria.push(y.costo_baterias);
                            me.label_local_Bateria.push(y.nombre_flota);
                            me.costo_total_baterias=parseFloat(y.costo_baterias)+parseFloat(me.costo_total_baterias);

                            }

                       });


                          me.optionsBateria={
                                     chart:{
                                        height: 350,
                                        type: 'bar',
                                      },
                                      plotOptions: {
                                        bar: {
                                          horizontal: false,
                                          columnWidth: '55%'
                                        },
                                      },

                                      colors:['#9C27B0'],
                                      
                                       title: {
                                        text: ''
                                      },
                                        subtitle: {
                                        text: '',
                                        align: 'center',
                                    },

                                      dataLabels: {
                                        enabled: false,
                                         enabledOnSeries: [1]
                                      },

                                      stroke: {
                                        show: true,
                                        width: 2,
                                        colors: ['transparent']
                                      },
                                      xaxis: {
                                        categories:  me.label_local_Bateria,
                                      },

                                      
                                     yaxis:[],
                                      fill: {
                                            opacity: 1
                                          }    


                                   };


                      me.seriesBateria=[{
                            name: 'costos por flota',
                            data: me.data_local_Bateria
                        }];



                        me.GenerarHistorialMant();
                       
                    }).catch(function(error){ console.log(error); });





 },



GenerarHistorialMant(){

              let me=this;

               this.Historial=[];
               this.costo_total_mantenimientos=0;

               let config={headers:{token:this.token}};
               this.mostrarLoading({titulo:'Accediendo a datos'});
             
              axios.post('mantenimientos-historial',
                     {   
                      'desde':this.fecha_inicio,
                      'hasta':this.fecha_fin,
                      'id_empresa':this.usuario.id_usr_empresa._id
                    },
                  config
                  ).then(function(response){
                      console.log(response);
                      me.Historial=response.data;

                       me.respuestas_cantidad++;

                      me.arrayitemFlota.map(function(y){

                          me.Historial.map(function(x){
                            if(x.id_vehiculo_mant.flota==y.nombre_flota){
                                if(y.costo_mantenimientos){
                                    y.costo_mantenimientos=parseFloat(x.costo_mant)+parseFloat(y.costo_mantenimientos);
                                }else{
                                    y.costo_mantenimientos=parseFloat(x.costo_mant)
                                }

                            }

                          });//recorrmos los historiales

                      });//recorremos las flotas

                        me.data_local_flotas=[];
                        me.label_local_flotas=[];

                       me.arrayitemFlota.map(function(y){

                       //  console.log('flota:'+y.nombre_flota+', '+y.costo_mantenimientos);

                            if((y.costo_mantenimientos )&& (parseFloat(y.costo_mantenimientos) >0)){

                            
                            me.data_local_flotas.push(y.costo_mantenimientos);
                            me.label_local_flotas.push(y.nombre_flota);

                            me.costo_total_mantenimientos=parseFloat(y.costo_mantenimientos)+parseFloat( me.costo_total_mantenimientos);

                            }

                       });

                          me.optionsFlota={
                                     chart:{
                                        height: 350,
                                        type: 'bar',
                                      },
                                      plotOptions: {
                                        bar: {
                                          horizontal: false,
                                          columnWidth: '55%'
                                        },
                                      },
                                      colors:[
                                      '#F44336', '#E91E63', '#9C27B0','#FFFF00','#008080','#9ACD32','#EE82EE', '#40E0D0','#FF6347', '#D8BFD8',
                                      '#D2B48C','#4682B4','#00FF7F', '#708090','#6A5ACD','#87CEEB','#C0C0C0','#A0522D','#2E8B57','#F4A460',
                                      '#FA8072','#8B4513','#4169E1','#BC8F8F','#FF0000','#F44336', '#E91E63', '#9C27B0','#FFFF00','#008080',
                                      '#9ACD32','#EE82EE','#40E0D0','#FF6347', '#D8BFD8','#D2B48C','#4682B4','#00FF7F','#708090','#6A5ACD',
                                      '#87CEEB','#C0C0C0','#A0522D','#2E8B57','#F4A460','#FA8072','#8B4513','#4169E1','#BC8F8F','#FF0000',
                                      '#87CEEB','#C0C0C0','#A0522D','#2E8B57','#F4A460', '#FA8072','#8B4513','#4169E1','#BC8F8F','#FF0000',
                                      '#F44336', '#E91E63','#9C27B0','#FFFF00','#008080','#9ACD32','#EE82EE', '#40E0D0','#FF6347', '#D8BFD8',
                                      '#D2B48C','#4682B4','#00FF7F','#708090','#6A5ACD','#F44336', '#E91E63', '#9C27B0','#FFFF00','#008080',
                                      '#9ACD32','#EE82EE','#40E0D0','#FF6347', '#D8BFD8','#D2B48C','#4682B4','#00FF7F', '#708090','#6A5ACD',
                                      '#87CEEB','#C0C0C0','#A0522D','#2E8B57','#F4A460','#FA8072','#8B4513','#4169E1','#BC8F8F','#FF0000',
                                      '#F44336', '#E91E63','#9C27B0','#FFFF00','#008080', '#9ACD32','#EE82EE', '#40E0D0','#FF6347', '#D8BFD8',
                                      '#D2B48C','#4682B4','#00FF7F','#708090','#6A5ACD'
                                      ],
                                      
                                       title: {
                                        text: ''
                                      },
                                        subtitle: {
                                        text: '',
                                        align: 'center',
                                    },

                                      dataLabels: {
                                        enabled: false,
                                         enabledOnSeries: [1]
                                      },

                                      stroke: {
                                        show: true,
                                        width: 2,
                                        colors: ['transparent']
                                      },
                                      xaxis: {
                                        categories:  me.label_local_flotas,
                                      },

                                      
                                     yaxis:[],
                                      fill: {
                                            opacity: 1
                                          }    


                                   };


                        me.seriesFlota=[{
                            name: 'costos por flota',
                            data: me.data_local_flotas
                        }];


                    
                        
                        me.ActualizarGraficasVeh();
                    


                      me.ocultarLoading();
                  }).catch(function(error){ console.log(error); });


          

          },//fin de generar datos



        ActualizarGraficasVeh(){

          console.log('cambiando datos de grafica vehiculos');


            this.arreglo_local_veh=[];
            this.label_local_Vehiculos=[];

            this.data_local_Vehiculos_bateria=[];
            this.data_local_Vehiculos_llanta=[];
            this.data_local_Vehiculos_mantenimiento=[];

            let me=this;
        


        //seleccionamos los vehiculos que pertencen a la flota seleccionada
          me.ArrayVehiculos.map(function(x){
                if(x.flota==me.flota_select){
                  me.arreglo_local_veh.push({'_id':x._id, 'nombre':x.nombre, 'costo_llantas':0, 'costo_bateria':0, 'costo_mantenimientos':0});
                }
          });


        //calculamos el detalle de cada vehiuclo de la flota seleccionada


             //añadimos el detalle de llantas
             me.arreglo_local_veh.map(function(y){

                        let estado_visible=false;
                       

                         //añadmos el detalle de llantas
                          me.ArrayLlantas.map(function(x){
                                          if(x.id_vehiculo_llanta._id==y._id){
                                            y.costo_llantas=parseFloat(x.costo)+parseFloat(y.costo_llantas);
                                            estado_visible=true;
                                          }
                          });

                        //añadmos el detalle de baterias
                          me.ArrayBaterias.map(function(x){
                                    if(x.id_vehiculo_bat._id==y._id){
                                      y.costo_bateria=parseFloat(x.costo)+parseFloat(y.costo_bateria);
                                      estado_visible=true;
                                    }

                          });


                        //añadmos el detalle de mantenimentos
                        me.Historial.map(function(x){
                                    if(x.id_vehiculo_mant._id==y._id){
                                        y.costo_mantenimientos=parseFloat(x.costo_mant)+parseFloat(y.costo_mantenimientos);
                                        estado_visible=true;
                                    }
                        });


                        if(estado_visible==true){
                          me.label_local_Vehiculos.push(y.nombre);

                          me.data_local_Vehiculos_bateria.push(y.costo_bateria);
                          me.data_local_Vehiculos_llanta.push(y.costo_llantas);
                          me.data_local_Vehiculos_mantenimiento.push(y.costo_mantenimientos);

                        }

             });


            
               me.optionsVehiculos={
                                     chart:{
                                        height: 350,
                                        type: 'bar',
                                      },
                                      plotOptions: {
                                        bar: {
                                          horizontal: false,
                                          columnWidth: '55%'
                                        },
                                      },
                                      
                                       title: {
                                        text: ''
                                      },
                                        subtitle: {
                                        text: '',
                                        align: 'center',
                                    },

                                      dataLabels: {
                                        enabled: false,
                                         enabledOnSeries: [1]
                                      },

                                      stroke: {
                                        show: true,
                                        width: 2,
                                        colors: ['transparent']
                                      },
                                      xaxis: {
                                        categories:  me.label_local_Vehiculos,
                                      },

                                      
                                     yaxis:[],
                                      fill: {
                                            opacity: 1
                                          }    


                                   };



                        me.seriesVehiculos=[{
                            name: 'costo Mantenimiento',
                            data: me.data_local_Vehiculos_mantenimiento
                        },{
                            name: 'costos bateria',
                            data: me.data_local_Vehiculos_bateria
                        },{
                             name: 'costos llantas',
                            data: me.data_local_Vehiculos_llanta
                        }];




        },



 },


 mounted(){
   
 },
 computed:{
      ...mapState(['usuario', 'token']),
 }
}
</script>